import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, IconButton, Menu, MenuItem, Box, Pagination, PaginationItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import ViewListIcon from '@mui/icons-material/ViewList';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';

const adjustAndFormatDate = (dateString) => {
  const [year, month, day] = dateString.split('T')[0].split('-');
  return `${day}/${month}/${year}`;
};

const CicloList = ({ ciclos = [], setCiclos }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [selectedCiclo, setSelectedCiclo] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;
  const navigate = useNavigate();

  const handleClickOpen = (ciclo) => {
    setSelectedCiclo(ciclo);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCiclo(null);
  };

  const handleDelete = async () => {
    if (selectedCiclo) {
      try {
        await axios.delete(`https://sistema.esct.net.br/back-end/api/ciclos/${selectedCiclo.id}`);
        setCiclos(ciclos.filter(c => c.id !== selectedCiclo.id));
        navigate('/admin/ciclos'); // Redireciona após a exclusão
      } catch (error) {
        console.error('Erro ao deletar ciclo:', error);
      } finally {
        handleClose();
      }
    }
  };

  const handleMenuOpen = (event, ciclo) => {
    event.stopPropagation();
    setSelectedCiclo(ciclo);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const renderMenu = () => (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem component={Link} to={`/admin/ciclos/edit/${selectedCiclo?.id}`} onClick={handleMenuClose}>
        <EditIcon /> Editar Ciclo
      </MenuItem>
      <MenuItem component={Link} to={`/admin/ciclos/view/${selectedCiclo?.id}`} onClick={handleMenuClose}>
        <ViewListIcon /> Ver Escalas
      </MenuItem>
      <MenuItem onClick={(event) => { event.stopPropagation(); handleClickOpen(selectedCiclo); handleMenuClose(); }}>
        <DeleteIcon /> Excluir
      </MenuItem>
    </Menu>
  );

  const sortedCiclos = ciclos.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const displayedCiclos = sortedCiclos.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div>
      {displayedCiclos.length > 0 ? displayedCiclos.map((ciclo, index) => (
        <Card key={ciclo.id} style={{ marginBottom: '1rem', position: 'relative', cursor: 'pointer' }} onClick={() => navigate(`/admin/ciclos/view/${ciclo.id}`)}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography variant="h5">Escala {ciclos.length - ((page - 1) * rowsPerPage + index)}</Typography>
                <Typography variant="body1">{adjustAndFormatDate(ciclo.data_inicio)} - {adjustAndFormatDate(ciclo.data_fim)}</Typography>
              </Box>
              {isMobile ? (
                <>
                  <IconButton onClick={(event) => handleMenuOpen(event, ciclo)} onMouseDown={(e) => e.stopPropagation()}>
                    <MoreVertIcon />
                  </IconButton>
                  {renderMenu()}
                </>
              ) : (
                <>
                  <Box display="flex" alignItems="center">
                    <Button component={Link} to={`/admin/ciclos/edit/${ciclo.id}`} variant="contained" color="primary" startIcon={<EditIcon />} style={{ marginRight: '0.5rem' }} onClick={(event) => event.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
                      Editar Ciclo
                    </Button>
                    <Button component={Link} to={`/admin/ciclos/view/${ciclo.id}`} variant="contained" startIcon={<ViewListIcon />} style={{ marginRight: '0.5rem' }} onClick={(event) => event.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
                      Ver Escalas
                    </Button>
                    <IconButton color="error" onClick={(event) => { event.stopPropagation(); handleClickOpen(ciclo); }} onMouseDown={(e) => e.stopPropagation()}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      )) : (
        <Typography variant="h6">Nenhum ciclo encontrado.</Typography>
      )}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(ciclos.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              {...item}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                },
              }}
            />
          )}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmar Exclusão"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza que deseja excluir o ciclo {selectedCiclo ? selectedCiclo.id : ''}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CicloList;
