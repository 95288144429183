import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, MenuItem, Box, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';
import { format, addDays, isBefore, startOfToday, getDay, isEqual, parseISO, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';

const JustificativaForm = () => {
  const [formData, setFormData] = useState({
    nomeCompleto: '',
    turma: '',
    dataAula: '',
    justificativa: '',
    documento: null
  });
  const [nomeArquivo, setNomeArquivo] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [datasValidas, setDatasValidas] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const turmas = [
    'Ginastica p/ Idosos',
    'Jiu-Jitsu (Manhã)',
    'Jiu-Jitsu (Tarde)',
    'Futsal (Manhã)',
    'Futsal (Tarde)',
    'Futebol Alto da Boa Vista (Tarde)',
    'Futebol Alto da Boa Vista (Manhã)',
    'CrossKids (Manhã)',
    'CrossKids (Tarde)',
    'Ritbox (Segunda e Quarta)',
    'Ritbox (Terça e Quinta)'
  ];

  const diasAula = useMemo(() => ({
    'Jiu-Jitsu (Manhã)': [2, 4], // Terça e Quinta
    'Jiu-Jitsu (Tarde)': [2, 4], // Terça, Quinta
    'Futsal (Manhã)': [3], // Quarta
    'Futsal (Tarde)': [1], // Segunda
    'Futebol Alto da Boa Vista (Tarde)': [2],
    'Futebol Alto da Boa Vista (Manhã)': [1], 
    'CrossKids (Manhã)': [5], // Sexta
    'CrossKids (Tarde)': [3], // Quarta
    'Ritbox (Segunda e Quarta)': [1, 3], // Segunda, Quarta
    'Ritbox (Terça e Quinta)': [2, 4], // Terça, Quinta
    'Ginastica p/ Idosos': [3, 5] // Quarta, Sexta
  }), []);

  useEffect(() => {
    if (formData.turma) {
      const diasValidos = diasAula[formData.turma];
      const hoje = startOfToday();
      const duasSemanasAtras = addDays(hoje, -14);

      let datas = [];
      for (let i = 0; i <= 14; i++) {
        const data = addDays(duasSemanasAtras, i);
        if (
          diasValidos.includes(getDay(data)) && // Verifica se o dia é válido
          (isBefore(data, hoje) || isEqual(data, hoje)) // Verifica se a data é antes de hoje ou igual a hoje
        ) {
          datas.push(data);
        }
      }
      setDatasValidas(datas.reverse()); // Inverte a ordem das datas para a mais recente primeiro
    } else {
      setDatasValidas([]);
    }
  }, [formData.turma, diasAula]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type.startsWith('image/') || file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      setFormData({
        ...formData,
        documento: file
      });
      setNomeArquivo(file.name);
      setUploadSuccess(true);
    } else {
      toast.error('Formato de arquivo não suportado. Por favor, envie uma imagem ou documento.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleConfirm = async () => {
    const data = new FormData();
    for (let key in formData) {
      data.append(key, formData[key]);
    }
    try {
      await axios.post('https://sistema.esct.net.br/back-end/api/justificativas', data);
      toast.success('Justificativa enviada com sucesso!');
      setOpen(false);
      navigate('/justificativa-confirmada', { state: { formData, nomeArquivo } });
      setFormData({
        nomeCompleto: '',
        turma: '',
        dataAula: '',
        justificativa: '',
        documento: null
      });
      setNomeArquivo('');
      setUploadSuccess(false);
    } catch (error) {
      toast.error('Erro ao enviar justificativa: ' + (error.response ? error.response.data.error : 'Servidor indisponível'));
      setOpen(false);
    }
  };

  const formatDateString = (dateString) => {
    const date = parseISO(dateString);
    if (!isValid(date)) {
      return '';
    }
    return format(date, "EEEE, dd/MM/yyyy", { locale: ptBR });
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      <Box display="flex" justifyContent="center" mb={2}>
        <img src="/LOGO.png" alt="Logo" style={{ width: '200px', height: 'auto' }} />
      </Box>
      <Paper elevation={3} style={{ padding: '2rem' }}>
        <Typography gutterBottom align="center" fontSize={'1.4em'}>
          Sistema de Justificativas
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Nome Completo"
            name="nomeCompleto"
            value={formData.nomeCompleto}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
            variant="outlined"
          />
          <TextField
            select
            label="Turma"
            name="turma"
            value={formData.turma}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
            variant="outlined"
          >
            {turmas.map((turma) => (
              <MenuItem key={turma} value={turma}>
                {turma}
              </MenuItem>
            ))}
          </TextField>
          {formData.turma && (
            <TextField
              select
              label="Data da Aula"
              name="dataAula"
              value={formData.dataAula}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              variant="outlined"
            >
              {datasValidas.map((data) => (
                <MenuItem key={data} value={format(data, 'yyyy-MM-dd')}>
                  {format(data, "EEEE, dd/MM/yyyy", { locale: ptBR })} {/* Nome do dia e data formatada */}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            label="Justificativa"
            name="justificativa"
            value={formData.justificativa}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
            variant="outlined"
            multiline
            rows={4}
          />
          <Button
            variant="contained"
            component="label"
            fullWidth
            style={{ marginTop: '1rem' }}
            startIcon={<CloudUploadIcon />}
          >
            Carregar Documento
            <input
              type="file"
              name="documento"
              hidden
              onChange={handleFileChange}
            />
          </Button>
          {uploadSuccess && (
            <Typography variant="body2" align="center" style={{ marginTop: '0.5rem', color: 'green' }}>
              Arquivo {/*{nomeArquivo}*/} carregado com sucesso!
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '1rem' }}
          >
            Enviar Justificativa
          </Button>
        </form>
      </Paper>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirmar Justificativa</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Por favor, confirme os detalhes da sua justificativa:
          </DialogContentText>
          <Box mb={2}>
            <Typography variant="body2"><strong>Nome Completo:</strong> {formData.nomeCompleto}</Typography>
            <Typography variant="body2"><strong>Turma:</strong> {formData.turma}</Typography>
            <Typography variant="body2"><strong>Data da Aula:</strong> {formatDateString(formData.dataAula)}</Typography>
            <Typography variant="body2"><strong>Justificativa:</strong> {formData.justificativa}</Typography>
            <Typography variant="body2"><strong>Documento Anexado:</strong> {nomeArquivo ? `Sim` : 'Não'}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">Cancelar</Button>
          <Button onClick={handleConfirm} color="primary">Confirmar Justificativa</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default JustificativaForm;
