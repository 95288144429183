import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Typography } from '@mui/material';
import { toast } from 'react-toastify';

const EditCicloForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');

  useEffect(() => {
    if (id) {
      axios.get(`https://sistema.esct.net.br/back-end/api/ciclos/${id}`)
        .then(response => {
          const ciclo = response.data;
          setDataInicio(ciclo.data_inicio.split('T')[0]);
          setDataFim(ciclo.data_fim.split('T')[0]);
        })
        .catch(error => console.error(error));
    }
  }, [id]);

  useEffect(() => {
    if (dataInicio) {
      const date = new Date(dataInicio);
      date.setDate(date.getDate() + 27); // 4 semanas depois
      setDataFim(date.toISOString().split('T')[0]);
    }
  }, [dataInicio]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const cicloData = { 

      data_inicio: `${dataInicio}T16:00:00`, 
      data_fim: `${dataFim}T16:00:00` 
    };
    axios.put(`https://sistema.esct.net.br/back-end/api/ciclos/${id}`, cicloData)
      .then(response => {
        toast.success('Ciclo atualizado com sucesso!');
        updateEscalasDates(id);
      })
      .catch(error => {
        toast.error('Erro ao atualizar o ciclo.');
        console.error(error);
      });
  };

  const updateEscalasDates = async (cicloId) => {
    try {
      const { data } = await axios.get(`https://sistema.esct.net.br/back-end/api/ciclos/${cicloId}/escalas`);
      const updatedEscalas = data.map((escala, index) => {
        const newDataInicio = new Date(dataInicio);
        newDataInicio.setDate(newDataInicio.getDate() + index * 7);
        newDataInicio.setHours(23, 1, 0, 0); // Set hour to 23:00:00
        const newDataFim = new Date(newDataInicio);
        newDataFim.setDate(newDataFim.getDate() + 6);
        newDataFim.setHours(23, 1, 0, 0); // Set hour to 23:00:00
        return {
          ...escala,
          data_inicio: newDataInicio.toISOString(),
          data_fim: newDataFim.toISOString(),
        };
      });

      await Promise.all(updatedEscalas.map(escala => axios.put(`https://sistema.esct.net.br/back-end/api/escalas/${escala.id}`, escala)));
      toast.success('Datas das escalas atualizadas com sucesso!');
      navigate(`/admin/ciclos/view/${cicloId}`);
    } catch (error) {
      toast.error('Erro ao atualizar as datas das escalas.');
      console.error(error);
    }
  };

  return (
    <Container>
      <Typography variant="h4">Editar Ciclo</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Data Início"
          type="date"
          value={dataInicio}
          onChange={(e) => setDataInicio(e.target.value)}
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="normal"
        />
        <TextField
          label="Data Fim"
          type="date"
          value={dataFim}
          readOnly
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">Salvar Ciclo</Button>
      </form>
    </Container>
  );
};

export default EditCicloForm;
