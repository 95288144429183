import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  TextField,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { getLicitacoes, deleteLicitacao, updateLicitacao } from '../api';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LicitacoesTable = () => {
  const [licitacoes, setLicitacoes] = useState([]);
  const [filteredLicitacoes, setFilteredLicitacoes] = useState([]);
  const [selectedLicitacao, setSelectedLicitacao] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Alterar para um valor padrão disponível
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [licitacaoToDelete, setLicitacaoToDelete] = useState(null);

  useEffect(() => {
    const fetchLicitacoes = async () => {
      try {
        const response = await getLicitacoes();
        const sortedLicitacoes = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setLicitacoes(sortedLicitacoes);
        setFilteredLicitacoes(sortedLicitacoes);
      } catch (error) {
        console.log(error)
        toast.error('Erro ao buscar licitação: ' + (error.response ? error.response.data.error : 'Servidor indisponível'));
      }
    };

    fetchLicitacoes();
  }, []);

  const handleDelete = async () => {
    try {
      await deleteLicitacao(licitacaoToDelete.id);
      setLicitacoes(licitacoes.filter((licitacao) => licitacao.id !== licitacaoToDelete.id));
      setFilteredLicitacoes(filteredLicitacoes.filter((licitacao) => licitacao.id !== licitacaoToDelete.id));
      handleCloseDeleteModal();
      handleCloseMenu();
      toast.success('Licitação deletada com sucesso');
    } catch (error) {
      toast.error('Erro ao deletar licitação: ' + (error.response ? error.response.data.error : 'Servidor indisponível'));
    }
  };

  const handleOpenMenu = (event, licitacao) => {
    setAnchorEl(event.currentTarget);
    setSelectedLicitacao(licitacao);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedLicitacao(null);
  };

  const handleOpenModal = (licitacao) => {
    setSelectedLicitacao(licitacao);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedLicitacao(null);
  };

  const handleOpenEditModal = (licitacao) => {
    setSelectedLicitacao(licitacao);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedLicitacao(null);
  };

  const handleOpenDeleteModal = (licitacao) => {
    setLicitacaoToDelete(licitacao);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setLicitacaoToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    filterLicitacoes(e.target.value, startDate, endDate);
  };

  const handleDateFilter = () => {
    filterLicitacoes(search, startDate, endDate);
  };

  const filterLicitacoes = (search, startDate, endDate) => {
    let filtered = licitacoes;

    if (search) {
      filtered = filtered.filter(
        (licitacao) =>
          licitacao.numero.toLowerCase().includes(search.toLowerCase()) ||
          licitacao.titulo.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (startDate) {
      filtered = filtered.filter((licitacao) => new Date(licitacao.dataCadastro) >= new Date(startDate));
    }

    if (endDate) {
      filtered = filtered.filter((licitacao) => new Date(licitacao.dataCadastro) <= new Date(endDate));
    }

    setFilteredLicitacoes(filtered);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('numero', selectedLicitacao.numero);
    formData.append('modalidade', selectedLicitacao.modalidade);
    formData.append('titulo', selectedLicitacao.titulo);
    formData.append('objeto', selectedLicitacao.objeto);
    formData.append('dataCadastro', selectedLicitacao.dataCadastro);
    selectedLicitacao.documentacao.forEach((doc, index) => {
      if (doc.file) {
        formData.append('documentacao', doc.file);
      }
      formData.append(`titulo-${index}`, doc.titulo);
    });

    try {
      await updateLicitacao(selectedLicitacao.id, formData);
      const updatedLicitacoes = licitacoes.map((licitacao) =>
        licitacao.id === selectedLicitacao.id ? selectedLicitacao : licitacao
      );
      setLicitacoes(updatedLicitacoes);
      setFilteredLicitacoes(updatedLicitacoes);
      handleCloseEditModal();
      toast.success('Licitação atualizada com sucesso')
    } catch (error) {
      toast.error('Erro ao atualizar a licitação: ' + (error.response ? error.response.data.error : 'Servidor indisponível'));
    }
  };

  const handleEditFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newDocumentacao = [...selectedLicitacao.documentacao, { file, titulo: '' }];
      setSelectedLicitacao({ ...selectedLicitacao, documentacao: newDocumentacao });
    }
  };

  const handleEditFileTitleChange = (index, e) => {
    const newDocumentacao = [...selectedLicitacao.documentacao];
    newDocumentacao[index].titulo = e.target.value;
    setSelectedLicitacao({ ...selectedLicitacao, documentacao: newDocumentacao });
  };

  const handleRemoveFile = (index) => {
    const newDocumentacao = selectedLicitacao.documentacao.filter((_, i) => i !== index);
    setSelectedLicitacao({ ...selectedLicitacao, documentacao: newDocumentacao });
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
      <Paper elevation={3} style={{ padding: '2rem', border: '1px solid #ddd', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <Box mt={5}>
          <Typography variant="h4" gutterBottom>
            Licitações Cadastradas
          </Typography>
          <Box mb={2} display="flex" justifyContent="space-between">
            <TextField
              label="Pesquisar"
              value={search}
              onChange={handleSearch}
              variant="outlined"
            />
            <Box display="flex">
              <TextField
                label="Data Início"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                margin="normal"
              />
              <TextField
                label="Data Fim"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleDateFilter}
                style={{ marginLeft: 10, marginTop: 16 }}
              >
                Filtrar
              </Button>
            </Box>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Número</TableCell>
                <TableCell>Título</TableCell>
                <TableCell>Data de Publicação</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLicitacoes
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((licitacao) => (
                  <TableRow key={licitacao.id}>
                    <TableCell>{licitacao.numero}</TableCell>
                    <TableCell>{licitacao.titulo}</TableCell>
                    <TableCell>{new Date(licitacao.dataCadastro).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenModal(licitacao)} color="primary">
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton onClick={() => handleOpenEditModal(licitacao)} color="primary">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={(e) => handleOpenMenu(e, licitacao)} color="primary">
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        {anchorEl && <MenuItem onClick={() => handleOpenDeleteModal(selectedLicitacao)}>Deletar</MenuItem>}
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredLicitacoes.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]} // Adicionar 5 aqui, se necessário
          />
        </Box>
      </Paper>
      {selectedLicitacao && (
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            maxHeight: '90vh', // Defina a altura máxima do modal
            overflow: 'auto' // Habilite a rolagem
          }}>
            <IconButton
              sx={{ position: 'absolute', top: 8, right: 8 }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              Detalhes da Licitação
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0277bd' }}>Número:</Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>{selectedLicitacao.numero}</Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0277bd' }}>Modalidade:</Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>{selectedLicitacao.modalidade}</Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0277bd' }}>Título:</Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>{selectedLicitacao.titulo}</Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0277bd' }}>Objeto:</Typography>
            <Typography variant="body1" sx={{ mb: 1, whiteSpace: 'pre-wrap' }}>{selectedLicitacao.objeto}</Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0277bd' }}>Data de Cadastro:</Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>{new Date(selectedLicitacao.dataCadastro).toLocaleDateString()}</Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0277bd' }}>Documentação:</Typography>
            <Table sx={{ width: 400 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Título do Documento</TableCell>
                  <TableCell>Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedLicitacao.documentacao.map((doc, index) => (
                  <TableRow key={index}>
                    <TableCell>{doc.titulo}</TableCell>
                    <TableCell>
                      <IconButton href={`http://192.168.0.145:3005/${doc.caminho}`} target="_blank" rel="noopener noreferrer" color="primary">
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Modal>
      )}
      {licitacaoToDelete && (
        <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
          <DialogTitle>Excluir Licitação</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Tem certeza que deseja excluir a licitação "{licitacaoToDelete.titulo}"?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteModal} color="secondary">Cancelar</Button>
            <Button onClick={handleDelete} color="primary">Excluir</Button>
          </DialogActions>
        </Dialog>
      )}

      {selectedLicitacao && (
        <Dialog open={openEditModal} onClose={handleCloseEditModal}>
          <DialogTitle>Editar Licitação</DialogTitle>
          <DialogContent>
            <form onSubmit={handleEditSubmit}>
              <TextField
                label="Número"
                name="numero"
                value={selectedLicitacao.numero}
                onChange={(e) => setSelectedLicitacao({ ...selectedLicitacao, numero: e.target.value })}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Modalidade"
                name="modalidade"
                value={selectedLicitacao.modalidade}
                onChange={(e) => setSelectedLicitacao({ ...selectedLicitacao, modalidade: e.target.value })}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Título da Licitação"
                name="titulo"
                value={selectedLicitacao.titulo}
                onChange={(e) => setSelectedLicitacao({ ...selectedLicitacao, titulo: e.target.value })}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Objeto"
                name="objeto"
                value={selectedLicitacao.objeto}
                onChange={(e) => setSelectedLicitacao({ ...selectedLicitacao, objeto: e.target.value })}
                fullWidth
                margin="normal"
                multiline
                rows={4}
                required
              />
              <TextField
                label="Data de Cadastro"
                name="dataCadastro"
                type="date"
                value={selectedLicitacao.dataCadastro}
                onChange={(e) => setSelectedLicitacao({ ...selectedLicitacao, dataCadastro: e.target.value })}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
              <Box mt={2} mb={2}>
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<AddCircleIcon />}
                >
                  Adicionar Documento
                  <input
                    type="file"
                    hidden
                    onChange={handleEditFileChange}
                    accept="application/pdf,image/*"
                  />
                </Button>
              </Box>
              {selectedLicitacao.documentacao.map((doc, index) => (
                <Box key={index} mt={2} mb={2} display="flex" alignItems="center">
                  <Typography variant="subtitle1">
                    Arquivo: {doc.file ? doc.file.name : doc.caminho}
                  </Typography>
                  <TextField
                    label={`Título do Documento ${index + 1}`}
                    value={doc.titulo}
                    onChange={(e) => handleEditFileTitleChange(index, e)}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <IconButton onClick={() => handleRemoveFile(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <DialogActions>
                <Button onClick={handleCloseEditModal} color="primary">
                  Cancelar
                </Button>
                <Button type="submit" color="primary">
                  Salvar
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </Container>
  );
};

export default LicitacoesTable;
