import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Button, Typography, Divider, Table, TableBody, TableCell, TableHead, TableRow, Box, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import AddIcon from '@mui/icons-material/Add';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const ViewCiclo = () => {
  const { id } = useParams();
  const [ciclo, setCiclo] = useState(null);

  useEffect(() => {
    axios.get(`https://sistema.esct.net.br/back-end/api/ciclos/${id}`)
      .then(response => {
        const cicloData = response.data;
        cicloData.data_inicio = adjustAndFormatDate(cicloData.data_inicio);
        cicloData.data_fim = adjustAndFormatDate(cicloData.data_fim);
        if (cicloData.Escalas) {
          cicloData.Escalas = cicloData.Escalas.map(escala => ({
            ...escala,
            data_inicio: adjustAndFormatDate(escala.data_inicio),
            data_fim: adjustAndFormatDate(escala.data_fim),
            observacoes: escala.observacoes.map(obs => ({
              ...obs,
              data: adjustAndFormatDate(obs.data)
            }))
          }));
        }
        setCiclo(cicloData);
      })
      .catch(error => {
        console.error('Erro ao buscar ciclo:', error);
      });
  }, [id]);

  const adjustAndFormatDate = (dateString) => {
    const [year, month, day] = dateString.split('T')[0].split('-');
    return `${day}/${month}/${year}`;
  };

  const generatePDF = async () => {
    const doc = new jsPDF();
    const logo = await loadImage('/LOGO.png');

    const pageWidth = doc.internal.pageSize.getWidth();

    // Adiciona o logotipo centralizado
    const logoWidth = 36;
    const logoHeight = 13;
    const logoX = (pageWidth - logoWidth) / 2;
    doc.addImage(logo, 'PNG', logoX, 10, logoWidth, logoHeight);

    // Adiciona o título e intervalo de datas
    doc.setFontSize(16);
    doc.text('Escala dos vigias', pageWidth / 2, 30, { align: 'center' });

    let startY = 40;

    ciclo.Escalas.forEach((escala, index) => {
      const tableData = [];
      tableData.push([{ content: `${escala.data_inicio} à ${escala.data_fim}`, colSpan: 2, styles: { halign: 'center', fillColor: '#1f4e78', textColor: '#FFFFFF' } }]);

      escala.vigias_locais.forEach(vigiaLocal => {
        tableData.push([vigiaLocal.vigia, vigiaLocal.local]);
      });

      if (escala.observacoes.length > 0) {
        const observacoesText = escala.observacoes.map(observacao => `Observação: ${observacao.data}: ${observacao.vigia} - ${observacao.local}`).join(' | ');
        tableData.push([{ content: observacoesText, colSpan: 2, styles: { halign: 'center', fontStyle: 'bold', fillColor: '#f1f1f1', textColor: '#000000' } }]);
      }

      // Adiciona a tabela para vigias e locais com cores personalizadas
      doc.autoTable({
        startY: startY,
        margin: { left: 10, right: 10, top: 0, bottom: -10 },
        tableWidth: pageWidth - 20,
        head: [['Vigia', 'Local']],
        body: tableData,
        theme: 'grid',
        styles: { halign: 'center', fontSize: 12 },
        headStyles: { fillColor: '#2d3393', textColor: '#FFFFFF' },
        bodyStyles: { fillColor: '#f1f1f1', textColor: '#000000' },
        columnStyles: {
          0: { cellWidth: 'auto' },
          1: { cellWidth: 'auto' },
        },
      });

      startY = doc.lastAutoTable.finalY + 5;
    });

    doc.save(`EscalaDosVigias_${ciclo.data_inicio}_a_${ciclo.data_fim}.pdf`);
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };

  if (!ciclo) {
    return <div>Carregando...</div>;
  }

  return (
    <Container>
      <Grid container direction="column" alignItems="flex-start" spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <Typography variant="h3">Escala</Typography>
          <Typography variant="h5">{ciclo.data_inicio} - {ciclo.data_fim}</Typography>
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to={ciclo.Escalas && ciclo.Escalas.length > 0 ? `/admin/escalas/edit/${id}` : `/admin/escalas/create/${id}`}
            variant="contained"
            color="secondary"
            sx={{ fontSize: '0.875rem', p: 1 }}
            startIcon={ciclo.Escalas && ciclo.Escalas.length > 0 ? <EditIcon /> : <AddIcon />}
          >
            {ciclo.Escalas && ciclo.Escalas.length > 0 ? 'Editar Escalas' : 'Criar Escalas'}
          </Button>
        </Grid>
        {ciclo.Escalas && ciclo.Escalas.length > 0 && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={generatePDF}
              sx={{ fontSize: '0.875rem', p: 1 }}
              startIcon={<GetAppIcon />}
            >
              Baixar PDF
            </Button>
          </Grid>
        )}
      </Grid>
      <Divider sx={{ my: 2 }} />
      {ciclo.Escalas && ciclo.Escalas.length > 0 ? (
        ciclo.Escalas.map((escala, index) => (
          <Box key={index} mb={4} sx={{ border: '1px solid #ccc', borderRadius: 2, p: 2 }}>
            <Typography variant="h5">Semana {index + 1}</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {escala.data_inicio} à {escala.data_fim}
            </Typography>
            <Table sx={{ fontSize: '1rem' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '1rem' }}>Vigia</TableCell>
                  <TableCell sx={{ fontSize: '1rem' }}>Local</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {escala.vigias_locais.map((vigiaLocal, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ fontSize: '1rem' }}>{vigiaLocal.vigia}</TableCell>
                    <TableCell sx={{ fontSize: '1rem' }}>{vigiaLocal.local}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {escala.observacoes.length > 0 && (
              <Box mt={2}>
                <Typography variant="subtitle1">Observações</Typography>
                {escala.observacoes.map((observacao, i) => (
                  <Typography key={i} variant="body2" sx={{ fontSize: '1rem' }}>
                    {observacao.data}: {observacao.vigia} - {observacao.local}
                  </Typography>
                ))}
              </Box>
            )}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))
      ) : (
        <Typography variant="h6" sx={{ mt: 2 }}>Nenhuma escala cadastrada.</Typography>
      )}
    </Container>
  );
};

export default ViewCiclo;
