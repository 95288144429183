import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Button } from '@mui/material';
import { toast } from 'react-toastify';
import SemanaForm from '../components/SemanaForm';
import moment from 'moment-timezone';

const AddEscalas = () => {
  const { cicloId } = useParams();
  const navigate = useNavigate();
  const [semanas, setSemanas] = useState([]);
  const [dataInicioCiclo, setDataInicioCiclo] = useState(null);

  useEffect(() => {
    if (cicloId) {
      axios.get(`https://sistema.esct.net.br/back-end/api/ciclos/${cicloId}`)
        .then(response => {
          const ciclo = response.data;
          setDataInicioCiclo(ciclo.data_inicio);
          setSemanas(Array.from({ length: 4 }, (_, index) => {
            const dataInicioSemana = moment(ciclo.data_inicio).add(index * 7, 'days').format('YYYY-MM-DD');
            const dataFimSemana = moment(dataInicioSemana).add(6, 'days').format('YYYY-MM-DD');
            return {
              order: index + 1, // Adicionando o índice
              data_inicio: dataInicioSemana,
              data_fim: dataFimSemana,
              vigias: Array.from({ length: 4 }, () => ({ vigia: '', local: '' })),
              observacoes: []
            };
          }));
        })
        .catch(error => {
          console.error('Erro ao buscar ciclo:', error);
        });
    }
  }, [cicloId]);

  const handleVigiaChange = (semanaIndex, vigiaIndex, field, value) => {
    const newVigias = semanas[semanaIndex].vigias.map((vigia, i) => (
      i === vigiaIndex ? { ...vigia, [field]: value } : vigia
    ));
    const newSemanas = semanas.map((semana, i) => (
      i === semanaIndex ? { ...semana, vigias: newVigias } : semana
    ));
    setSemanas(newSemanas);
  };

  const handleObservacaoChange = (semanaIndex, observacaoIndex, field, value) => {
    const newObservacoes = semanas[semanaIndex].observacoes.map((observacao, i) => (
      i === observacaoIndex ? { ...observacao, [field]: value } : observacao
    ));
    const newSemanas = semanas.map((semana, i) => (
      i === semanaIndex ? { ...semana, observacoes: newObservacoes } : semana
    ));
    setSemanas(newSemanas);
  };

  const handleAddObservacao = (semanaIndex) => {
    const newObservacoes = [...semanas[semanaIndex].observacoes, { data: '', vigia: '', local: '' }];
    const newSemanas = semanas.map((semana, i) => (
      i === semanaIndex ? { ...semana, observacoes: newObservacoes } : semana
    ));
    setSemanas(newSemanas);
  };

  const handleRemoveObservacao = (semanaIndex, observacaoIndex) => {
    const newObservacoes = semanas[semanaIndex].observacoes.filter((_, i) => i !== observacaoIndex);
    const newSemanas = semanas.map((semana, i) => (
      i === semanaIndex ? { ...semana, observacoes: newObservacoes } : semana
    ));
    setSemanas(newSemanas);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const escalas = semanas.map(semana => ({
      data_inicio: semana.data_inicio,
      data_fim: semana.data_fim,
      vigias_locais: semana.vigias,
      observacoes: semana.observacoes,
      ciclo_id: cicloId,
      order: semana.order // Adicionando o campo order
    }));

    try {
      for (const escala of escalas) {
        await axios.post('https://sistema.esct.net.br/back-end/api/escalas', escala);
      }
      toast.success('Escalas criadas com sucesso!');
      navigate('/admin/ciclos');
    } catch (error) {
      toast.error('Erro ao salvar as escalas.');
      console.error(error);
    }
  };

  const adjustAndFormatDate = (dateString) => {
    const [year, month, day] = dateString.split('T')[0].split('-');
    return `${day}/${month}/${year}`;
  };

  return (
    <Container>
      <Typography variant="h4">Adicionar Escalas</Typography>
      <form onSubmit={handleSubmit}>
        {semanas.map((semana, index) => (
          <div key={index}>

            <SemanaForm
              semana={semana}
              onChangeVigia={(vigiaIndex, field, value) => handleVigiaChange(index, vigiaIndex, field, value)}
              onChangeObservacao={(obsIndex, field, value) => handleObservacaoChange(index, obsIndex, field, value)}
              onAddObservacao={() => handleAddObservacao(index)}
              onRemoveObservacao={(obsIndex) => handleRemoveObservacao(index, obsIndex)}
              semanaIndex={index + 1} // Adicionando o índice
            />
          </div>
        ))}
        <Button type="submit" variant="contained" color="primary">Salvar Escalas</Button>
      </form>
    </Container>
  );
};

export default AddEscalas;
