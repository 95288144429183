// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import "./style.css"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';

const theme = createTheme({
  palette: {
    mode: 'light', // ou 'dark' se você quiser o modo escuro
  },
});


// Adicionando um interceptor para adicionar o token JWT a todas as requisições
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <ThemeProvider theme={theme}>
  <Router>
    <App />
  </Router>,
  </ThemeProvider>,

  document.getElementById('root')
);
