import axios from 'axios';

// Criando a instância do axios com a configuração base
const api = axios.create({
  baseURL: 'https://sistema.esct.net.br/',
});

// Adicionando um interceptor para adicionar o token JWT a todas as requisições
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Funções para chamadas de API
export const getLicitacoes = () => api.get('/back-end/api/licitacoes');
export const addLicitacao = (licitacao) => api.post('/back-end/api/licitacoes', licitacao, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});
export const updateLicitacao = (id, licitacao) => api.put(`/back-end/api/licitacoes/${id}`, licitacao);
export const deleteLicitacao = (id) => api.delete(`/back-end/api/licitacoes/${id}`);

// Adicionando novas rotas para ciclos e escalas
export const createCiclo = (ciclo) => api.post('/back-end/api/ciclos', ciclo);
export const getCiclos = () => api.get('/back-end/api/ciclos');
export const getCicloById = (id) => api.get(`/back-end/api/ciclos/${id}`);
export const updateCiclo = (id, ciclo) => api.put(`/back-end/api/ciclos/${id}`, ciclo);
export const deleteCiclo = (id) => api.delete(`/back-end/api/ciclos/${id}`);
export const getEscalasByCicloId = (cicloId) => api.get(`/back-end/api/ciclos/${cicloId}/escalas`);

export default api;
