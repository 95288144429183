import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import AdminDashboard from './pages/Admin';
import Dashboard from './pages/Dashboard';
import CiclosDashboard from './pages/CiclosDashboard';
import CicloForm from './components/CicloForm';
import AddEscalas from './pages/AddEscalas';
import EscalasList from './pages/EscalasList';
import EditEscalas from './pages/EditEscalas';
import ViewCiclo from './pages/ViewCiclo';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout';
import Justificativa from './components/JustificativaForm';
import AddLicitacao from './pages/AddLicitacao';
import LicitacoesList from './pages/LicitacoesList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditCiclo from './pages/EditCiclo';
import JustificativaConfirmada from './components/JustificativaConfirmada';


function App() {
  return (
    <div>
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/admin/dashboard" replace />} /> {/* Define a tela padrão */}
          <Route path="admin" element={<PrivateRoute allowedRoles={['admin', 'coordenador']} />}>

            <Route path="ciclos" element={<CiclosDashboard />} />
            <Route path="ciclos/create" element={<CicloForm />} />
            <Route path="escalas/edit/:cicloId" element={<EditEscalas />} />
            <Route path="ciclos/view/:id" element={<ViewCiclo />} />
            <Route path="escalas/create/:cicloId" element={<AddEscalas />} />
            <Route path="ciclos/:cicloId/escalas" element={<EscalasList />} />
            <Route path="licitacoes/add" element={<AddLicitacao />} />
            <Route path="licitacoes/list" element={<LicitacoesList />} />
            <Route path="ciclos/edit/:id" element={<EditCiclo />} />
          </Route>
          <Route path="admin" element={<PrivateRoute allowedRoles={['admin', 'coordenador','secretaria']} />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="justificativas" element={<AdminDashboard />} />
          </Route>
        </Route>
        <Route path="justificativas" element={<Justificativa />} />
        <Route path="justificativa-confirmada" element={<JustificativaConfirmada />} />

      </Routes>
    </div>
  );
}

export default App;
