import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Button, Card, CardContent } from '@mui/material';

const EscalasList = () => {
  const { cicloId } = useParams();
  const [escalas, setEscalas] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://sistema.esct.net.br/back-end/api/ciclos/${cicloId}/escalas`)
      .then(response => setEscalas(response.data))
      .catch(error => console.error(error));
  }, [cicloId]);

  return (
    <Container>
      <Typography variant="h4">Escalas</Typography>
      {escalas.map(escala => (
        <Card key={escala.id} style={{ marginBottom: '1rem' }}>
          <CardContent>
            <Typography variant="h5">Semana de {escala.data_inicio} a {escala.data_fim}</Typography>
            {/* Adicionar visualização dos detalhes da escala aqui */}
            <Button onClick={() => navigate(`/admin/escalas/edit/${escala.id}`)} variant="contained" color="primary">
              Editar
            </Button>
          </CardContent>
        </Card>
      ))}
      <Button variant="contained" color="secondary" onClick={() => navigate('/admin/ciclos')}>
        Voltar
      </Button>
    </Container>
  );
};

export default EscalasList;
