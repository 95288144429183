import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Button } from '@mui/material';
import { toast } from 'react-toastify';
import SemanaForm from '../components/SemanaForm';
import moment from 'moment-timezone';

const EditEscalas = () => {
  const { cicloId } = useParams();
  const navigate = useNavigate();
  const [semanas, setSemanas] = useState([]);

  useEffect(() => {
    axios.get(`https://sistema.esct.net.br/back-end/api/ciclos/${cicloId}/escalas`)
      .then(response => {
        const escalas = response.data.map((escala, index) => ({
          id: escala.id,
          data_inicio: escala.data_inicio,
          data_fim: escala.data_fim, 
          vigias: escala.vigias_locais,
          observacoes: escala.observacoes,
          index: index + 1 // Adicionando o índice
        }));
        setSemanas(escalas);
      })
      .catch(error => console.error(error));
  }, [cicloId]);

  const handleVigiaChange = (semanaIndex, vigiaIndex, field, value) => {
    const newVigias = semanas[semanaIndex].vigias.map((vigia, i) => (
      i === vigiaIndex ? { ...vigia, [field]: value } : vigia
    ));
    const newSemanas = semanas.map((semana, i) => (
      i === semanaIndex ? { ...semana, vigias: newVigias } : semana
    ));
    setSemanas(newSemanas);
  };

  const handleObservacaoChange = (semanaIndex, observacaoIndex, field, value) => {
    const newObservacoes = semanas[semanaIndex].observacoes.map((observacao, i) => (
      i === observacaoIndex ? { ...observacao, [field]: value } : observacao
    ));
    const newSemanas = semanas.map((semana, i) => (
      i === semanaIndex ? { ...semana, observacoes: newObservacoes } : semana
    ));
    setSemanas(newSemanas);
  };

  const handleAddObservacao = (semanaIndex) => {
    const newObservacoes = [...semanas[semanaIndex].observacoes, { data: '', vigia: '', local: '' }];
    const newSemanas = semanas.map((semana, i) => (
      i === semanaIndex ? { ...semana, observacoes: newObservacoes } : semana
    ));
    setSemanas(newSemanas);
  };

  const handleRemoveObservacao = (semanaIndex, observacaoIndex) => {
    const newObservacoes = semanas[semanaIndex].observacoes.filter((_, i) => i !== observacaoIndex);
    const newSemanas = semanas.map((semana, i) => (
      i === semanaIndex ? { ...semana, observacoes: newObservacoes } : semana
    ));
    setSemanas(newSemanas);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const escalas = semanas.map(semana => ({
      id: semana.id,
      vigias_locais: semana.vigias,
      observacoes: semana.observacoes,
      ciclo_id: cicloId
    }));

    Promise.all(escalas.map(escala => axios.put(`https://sistema.esct.net.br/back-end/api/escalas/${escala.id}`, escala)))
      .then(() => {
        toast.success('Escalas atualizadas com sucesso!');
        navigate(`/admin/ciclos/view/${cicloId}`);
      })
      .catch(error => {
        toast.error('Erro ao atualizar as escalas.');
        console.error(error);
      });
  };

  return (
    <Container>
      <Typography variant="h4">Editar Escalas</Typography>
      <form onSubmit={handleSubmit}>
        {semanas.map((semana, index) => (
          <div key={index}>
           {/* <Typography variant="h6">Semana {index + 1}</Typography>*/}
            <SemanaForm
              semana={semana}
              onChangeVigia={(vigiaIndex, field, value) => handleVigiaChange(index, vigiaIndex, field, value)}
              onChangeObservacao={(obsIndex, field, value) => handleObservacaoChange(index, obsIndex, field, value)}
              onAddObservacao={() => handleAddObservacao(index)}
              onRemoveObservacao={(obsIndex) => handleRemoveObservacao(index, obsIndex)}
              semanaIndex={index + 1} // Adicionando o índice
            />
          </div>
        ))}
        <Button type="submit" variant="contained" color="primary">Salvar Escalas</Button>
      </form>
    </Container>
  );
};

export default EditEscalas;
