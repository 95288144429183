import React, { useState, useEffect } from 'react';
import { Container, Paper, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, TextField, MenuItem, Select, FormControl, InputLabel, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, IconButton, TablePagination, Menu, MenuItem as MenuOption } from '@mui/material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { format, parseISO, startOfMonth, endOfMonth, getDay, eachDayOfInterval } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Print, GetApp, Visibility, MoreVert, Info, ArrowUpward, ArrowDownward, ZoomIn, ZoomOut } from '@mui/icons-material';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import '../api.js';

const AdminDashboard = () => {
  const [justificativas, setJustificativas] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTurma, setSelectedTurma] = useState('');
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().substr(0, 7)); // Default to current month
  const [datasValidas, setDatasValidas] = useState([]);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentJustificativa, setCurrentJustificativa] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedForOptions, setSelectedForOptions] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [zoomLevel, setZoomLevel] = useState(1);
  const location = useLocation();

  const turmas = [
    'Ginastica p/ Idosos',
    'Jiu-Jitsu (Manhã)',
    'Jiu-Jitsu (Tarde)',
    'Futsal (Manhã)',
    'Futsal (Tarde)',
    'Futebol Alto da Boa Vista (Tarde)',
    'Futebol Alto da Boa Vista (Manhã)',
    'CrossKids (Manhã)',
    'CrossKids (Tarde)',
    'Ritbox (Segunda e Quarta)',
    'Ritbox (Terça e Quinta)'
  ];

  const diasAula = {
    'Jiu-Jitsu (Manhã)': [2, 4], // Terça e Quinta
    'Jiu-Jitsu (Tarde)': [2, 4], // Terça, Quinta
    'Futsal (Manhã)': [3], // Quarta
    'Futsal (Tarde)': [1], // Segunda
    'Futebol Alto da Boa Vista (Tarde)': [2],
    'Futebol Alto da Boa Vista (Manhã)': [1],
    'CrossKids (Manhã)': [5], // Sexta
    'CrossKids (Tarde)': [3], // Quarta
    'Ritbox (Segunda e Quarta)': [1, 3], // Segunda, Quarta
    'Ritbox (Terça e Quinta)': [2, 4], // Terça, Quinta
    'Ginastica p/ Idosos': [3, 5] // Quarta, Sexta
  };

  useEffect(() => {
    const fetchJustificativas = async () => {
      try {
        const response = await axios.get('https://sistema.esct.net.br/back-end/api/justificativas', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setJustificativas(response.data);
      } catch (error) {
        toast.error('Erro ao buscar justificativas: ' + (error.response ? error.response.data.error : 'Servidor indisponível'));
      }
    };
    fetchJustificativas();
  }, []);

  useEffect(() => {
    if (selectedTurma && selectedMonth) {
      const diasValidos = diasAula[selectedTurma];
      const [year, month] = selectedMonth.split('-');
      const startDate = startOfMonth(new Date(year, month - 1));
      const endDate = endOfMonth(new Date(year, month - 1));
      const intervalo = eachDayOfInterval({ start: startDate, end: endDate });

      let datas = [];
      intervalo.forEach((data) => {
        if (diasValidos.includes(getDay(data))) {
          datas.push(data);
        }
      });

      setDatasValidas(datas);
    } else {
      setDatasValidas([]);
    }
  }, [selectedTurma, selectedMonth]);

  useEffect(() => {
    if (location.state) {
      setSelectedTurma(location.state.turma);
      setSelectedDates([]); // Reset selected dates when turma changes
    }
  }, [location.state]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleTurmaChange = (event) => {
    setSelectedTurma(event.target.value);
    setSelectedDates([]); // Reset selected dates when turma changes
  };

  const handleDateChange = (event) => {
    setSelectedDates(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setSelectedDates([]); // Reset selected dates when month changes
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedJustificativas = justificativas
    .filter((justificativa) => {
      const formattedDate = format(parseISO(justificativa.dataAula), 'yyyy-MM-dd');
      const matchesSearch = justificativa.nomeCompleto.toLowerCase().includes(searchTerm.toLowerCase()) ||
        justificativa.turma.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesTurma = selectedTurma ? justificativa.turma === selectedTurma : true;
      const matchesDate = selectedDates.length ? selectedDates.includes(formattedDate) : true;
      return matchesSearch && matchesTurma && matchesDate;
    })
    .sort((a, b) => {
      if (sortConfig.key === 'turma') {
        return sortConfig.direction === 'asc'
          ? a.turma.localeCompare(b.turma)
          : b.turma.localeCompare(a.turma);
      }
      return sortConfig.direction === 'asc'
        ? new Date(a.createdAt) - new Date(b.createdAt)
        : new Date(b.createdAt) - new Date(a.createdAt);
    });

  const handlePrint = () => {
    const printContents = document.getElementById('printableArea').innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  const handleOpenDetailsModal = (justificativa) => {
    setCurrentJustificativa(justificativa);
    setIsDetailsModalOpen(true);
  };

  const handleOpenDeleteModal = (justificativa) => {
    setCurrentJustificativa(justificativa);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDetailsModal = () => {
    setIsDetailsModalOpen(false);
    setCurrentJustificativa(null);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setCurrentJustificativa(null);
  };

  const handleDeleteJustificativa = async () => {
    if (!currentJustificativa) return;

    try {
      await axios.delete(`https://sistema.esct.net.br/back-end/api/justificativas/${currentJustificativa.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setJustificativas(justificativas.filter(j => j.id !== currentJustificativa.id));
      setIsDeleteModalOpen(false);
      setCurrentJustificativa(null);
    } catch (error) {
      toast.error('Erro ao deletar justificativas: ' + (error.response ? error.response.data.error : 'Servidor indisponível'));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClick = (event, justificativa) => {
    setAnchorEl(event.currentTarget);
    setSelectedForOptions(justificativa);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedForOptions(null);
  };

  const handlePrintDocument = () => {
    if (!currentJustificativa) return;

    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      console.error('Failed to open new window. Check if pop-ups are blocked.');
      return;
    }

    const documentURL = `https://sistema.esct.net.br/back-end/${currentJustificativa.documento}`;
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Document</title>
        </head>
        <body>
          <iframe src="${documentURL}" onload="this.contentWindow.print(); this.contentWindow.onafterprint = () => window.close();" style="width: 100%; height: 100%; border: none;"></iframe>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  const handleViewDocument = () => {
    if (!currentJustificativa) return;

    const documentURL = `https://sistema.esct.net.br/back-end/${currentJustificativa.documento}`;
    const extension = documentURL.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];

    if (imageExtensions.includes(extension)) {
      setImageSrc(documentURL);
      setZoomLevel(1);
      setIsImageModalOpen(true);
    } else {
      window.open(documentURL, '_blank');
    }
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
    setImageSrc('');
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => (prevZoomLevel > 0.1 ? prevZoomLevel - 0.1 : 0.1));
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
      <Paper elevation={3} style={{ padding: '2rem' }}>
        <Typography variant="h4" gutterBottom align="center">
          Justificativas
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
          <TextField
            label="Pesquisar"
            value={searchTerm}
            onChange={handleSearch}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            style={{ marginBottom: '1rem' }}
          />
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems={{ xs: 'flex-start', md: 'center' }} width="100%">
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120, width: window.innerWidth <= 600 ? '100%' : 'auto' }}>
              <InputLabel shrink>Turma</InputLabel>
              <Select
                value={selectedTurma}
                onChange={handleTurmaChange}
                label="Turma"
                displayEmpty
              >
                <MenuItem value="">
                  <em>Todas as Turma</em>
                </MenuItem>
                {turmas.map((turma) => (
                  <MenuItem key={turma} value={turma}>
                    {turma}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120, width: window.innerWidth <= 600 ? '100%' : 'auto' }}>
              <InputLabel shrink>Mês</InputLabel>
              <TextField
                type="month"
                value={selectedMonth}
                onChange={handleMonthChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120, width: window.innerWidth <= 600 ? '100%' : 'auto' }}>
              <InputLabel shrink>Data</InputLabel>
              <Select
                multiple
                value={selectedDates}
                onChange={handleDateChange}
                label="Data"
                displayEmpty
                renderValue={(selected) => selected.join(', ')}
              >
                {datasValidas.map((data) => (
                  <MenuItem key={data} value={format(data, 'yyyy-MM-dd')}>
                    {format(data, "EEEE, dd/MM/yyyy", { locale: ptBR })} {/* Nome do dia e data formatada */}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button color="primary" onClick={handlePrint} size="small" className="no-print">
              <LocalPrintshopIcon onClick={handlePrint} />
            </Button>
          </Box>
        </Box>
        <div id="printableArea">
          <Typography variant="h5" gutterBottom align="center">
            {selectedTurma} - {selectedDates.map(date => format(parseISO(date), "dd/MM/yyyy", { locale: ptBR })).join(', ')}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Nome Completo</strong></TableCell>
                  <TableCell onClick={() => handleSort('turma')} style={{ cursor: 'pointer' }}>
                    <strong>Turma</strong>
                    {sortConfig.key === 'turma' && (
                      sortConfig.direction === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell><strong>Data da Aula</strong></TableCell>
                  <TableCell className="no-print"><strong>Ações</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedJustificativas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((justificativa) => (
                  <TableRow key={justificativa.id}>
                    <TableCell>{justificativa.nomeCompleto}</TableCell>
                    <TableCell>{justificativa.turma}</TableCell>
                    <TableCell>{format(parseISO(justificativa.dataAula), "EEEE, dd/MM/yyyy", { locale: ptBR })}</TableCell>
                    <TableCell className="no-print">
                      <IconButton onClick={() => handleOpenDetailsModal(justificativa)} color="primary">
                        <Info />
                      </IconButton>
                      <IconButton onClick={(event) => handleMenuClick(event, justificativa)} color="primary">
                        <MoreVert />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuOption onClick={() => handleOpenDeleteModal(selectedForOptions)}>Excluir</MenuOption>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[25, 35, 45]}
              component="div"
              count={sortedJustificativas.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      </Paper>

      {/* Modal de Detalhes */}
      <Dialog open={isDetailsModalOpen} onClose={handleCloseDetailsModal}>
        <DialogTitle>Detalhes da Justificativa</DialogTitle>
        <DialogContent dividers>
          {currentJustificativa && (
            <Box mb={2} sx={{ fontSize: '1.2rem' }}> {/* Aumentar a fonte */}
              <Typography variant="body2" sx={{ fontSize: '1.2rem' }}><strong>Nome Completo:</strong> {currentJustificativa.nomeCompleto}</Typography>
              <Typography variant="body2" sx={{ fontSize: '1.2rem' }}><strong>Turma:</strong> {currentJustificativa.turma}</Typography>
              <Typography variant="body2" sx={{ fontSize: '1.2rem' }}><strong>Data da Aula:</strong> {format(parseISO(currentJustificativa.dataAula), "EEEE, dd/MM/yyyy", { locale: ptBR })}</Typography>
              <Typography variant="body2" sx={{ fontSize: '1.2rem' }}><strong>Justificativa:</strong> {currentJustificativa.justificativa}</Typography>
              {currentJustificativa.documento && (
                <Box mt={2}>
                  <IconButton onClick={handleViewDocument} color="primary">
                    <Visibility />
                  </IconButton>
                  <IconButton
                    onClick={async () => {
                      try {
                        const response = await fetch(`https://sistema.esct.net.br/back-end/${currentJustificativa.documento}`);
                        if (!response.ok) {
                          throw new Error('Network response was not ok');
                        }
                        const blob = await response.blob();
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.setAttribute('download', currentJustificativa.documento.split('/').pop());
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(link.href); // Libera o objeto URL
                      } catch (error) {
                        console.error('Error downloading the file:', error);
                      }
                    }}
                    color="primary"
                  >
                    <GetApp />
                  </IconButton>
                  <IconButton onClick={handlePrintDocument} color="primary">
                    <Print />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDetailsModal} color="secondary">Fechar</Button>
        </DialogActions>
      </Dialog>

      {/* Modal de Imagem */}
      <Dialog open={isImageModalOpen} onClose={handleCloseImageModal} maxWidth="lg" fullWidth>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <img src={imageSrc} alt="Documento" style={{ width: `${zoomLevel * 100}%`, height: 'auto' }} />
            <Box mt={2}>
              <IconButton onClick={handleZoomIn} color="primary">
                <ZoomIn />
              </IconButton>
              <IconButton onClick={handleZoomOut} color="primary">
                <ZoomOut />
              </IconButton>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImageModal} color="primary">Fechar</Button>
        </DialogActions>
      </Dialog>

      {/* Modal de Confirmação de Exclusão */}
      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Excluir Justificativa</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir esta justificativa?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="secondary">Cancelar</Button>
          <Button onClick={handleDeleteJustificativa} color="primary">Excluir</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminDashboard;
