import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Select, MenuItem, InputLabel, FormControl, Box, Typography, IconButton } from '@mui/material';
import axios from 'axios';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

const locais = ["ONG DIA", "ONG NOITE", "ESCRITORIO", "FOLGA"];

const CustomSelect = styled(Select)({
  '& .MuiSelect-select': {
    fontSize: '0.8rem', // Tamanho da fonte do item selecionado
    padding: '6px',
  },
});

const SemanaForm = ({ semana, onChangeVigia, onChangeObservacao, onAddObservacao, onRemoveObservacao, semanaIndex }) => {
  const [vigias, setVigias] = useState([]);
  const [canAddObservation, setCanAddObservation] = useState(true);

  useEffect(() => {
    axios.get('https://sistema.esct.net.br/back-end/api/vigias')
      .then(response => setVigias(response.data))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    // Verifica se já existe uma observação e define canAddObservation para false
    setCanAddObservation(semana.observacoes.length === 0);
  }, [semana.observacoes]);

  const handleAddObservacao = () => {
    onAddObservacao();
    setCanAddObservation(false);
  };

  const handleRemoveObservacao = (index) => {
    onRemoveObservacao(index);
    setCanAddObservation(true);
  };

  const adjustAndFormatDate = (dateString) => {
    const [year, month, day] = dateString.split('T')[0].split('-');
    return `${day}/${month}/${year}`;
  };

  return (
    <Box mb={4} sx={{ border: '1px solid #ccc', padding: 2, borderRadius: 2 }}>
      <Typography variant="h6">
        Semana {semanaIndex}
        </Typography>
        <Typography variant="body1" component="span"  sx={{ display: { xs: 'block', md: 'inline'} }} mb={2}>
          {adjustAndFormatDate(semana.data_inicio)} - {adjustAndFormatDate(semana.data_fim)}
      </Typography>
      {semana.vigias.map((vigia, index) => (
        <Grid container spacing={1} alignItems="center" key={index} mb={1}>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: '0.8rem' }}>Vigia</InputLabel>
              <CustomSelect
                value={vigia.vigia}
                onChange={(e) => onChangeVigia(index, 'vigia', e.target.value)}
                inputProps={{ style: { fontSize: '0.8rem', padding: '6px' } }}
                fullWidth
                label="Vigia"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .MuiMenuItem-root': {
                        fontSize: '0.8rem', // Tamanho da fonte das opções no dropdown
                      },
                    },
                  },
                }}
              >
                {vigias.map((v) => (
                  <MenuItem key={v.id} value={v.nome} style={{ fontSize: '0.8rem' }}>
                    {v.nome}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="center">
            <ArrowForwardIcon fontSize="small" />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: '0.8rem' }}>Local</InputLabel>
              <CustomSelect
                value={vigia.local}
                onChange={(e) => onChangeVigia(index, 'local', e.target.value)}
                inputProps={{ style: { fontSize: '0.8rem', padding: '6px' } }}
                fullWidth
                label="Local"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .MuiMenuItem-root': {
                        fontSize: '0.8rem', // Tamanho da fonte das opções no dropdown
                      },
                    },
                  },
                }}
              >
                {locais.map((local, idx) => (
                  <MenuItem key={idx} value={local} style={{ fontSize: '0.8rem' }}>
                    {local}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Grid>
        </Grid>
      ))}
      {canAddObservation && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddObservacao}
          startIcon={<AddIcon />}
          sx={{ mb: 2, fontSize: '0.8rem', padding: '6px 12px' }}
        >
          Adicionar Observação
        </Button>
      )}
      {semana.observacoes.length > 0 && (
        <Typography variant="subtitle1" mt={2} mb={1}>Observação:</Typography>
      )}
      {semana.observacoes.map((observacao, index) => (
        <Grid container spacing={1} alignItems="center" key={index} mt={1}>
          <Grid item xs={12} md={3} sx={{ mt: { xs: -3, md: 0 }, mb: { xs: -3, md: 0 } }}>
            <TextField
              label="Data"
              type="date"
              value={observacao.data}
              onChange={(e) => onChangeObservacao(index, 'data', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              margin="normal"
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: '0.8rem', // Tamanho da fonte do input de data
                  padding: '6px',
                },
              }}
            />
          </Grid>
          <Grid item xs={5} md={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel style={{ fontSize: '0.8rem' }}>Vigia</InputLabel>
              <CustomSelect
                value={observacao.vigia}
                onChange={(e) => onChangeObservacao(index, 'vigia', e.target.value)}
                inputProps={{ style: { fontSize: '0.8rem', padding: '6px' } }}
                fullWidth
                label="Vigia"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .MuiMenuItem-root': {
                        fontSize: '0.8rem', // Tamanho da fonte das opções no dropdown
                      },
                    },
                  },
                }}
              >
                {vigias.map((v) => (
                  <MenuItem key={v.id} value={v.nome} style={{ fontSize: '0.8rem' }}>
                    {v.nome}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel style={{ fontSize: '0.8rem' }}>Local</InputLabel>
              <CustomSelect
                value={observacao.local}
                onChange={(e) => onChangeObservacao(index, 'local', e.target.value)}
                inputProps={{ style: { fontSize: '0.8rem', padding: '6px' } }}
                fullWidth
                label="Local"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .MuiMenuItem-root': {
                        fontSize: '0.8rem', // Tamanho da fonte das opções no dropdown
                      },
                    },
                  },
                }}
              >
                {locais.map((local, idx) => (
                  <MenuItem key={idx} value={local} style={{ fontSize: '0.8rem' }}>
                    {local}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={1} md={1} display="flex" justifyContent="center" mt={{ xs: 1, md: 0 }}>
            <IconButton onClick={() => handleRemoveObservacao(index)} color="error" aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default SemanaForm;
