// src/components/AddLicitacaoForm.js
import React, { useState } from 'react';
import { TextField, Button, Container, Box, Typography, IconButton, Paper } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { addLicitacao } from '../api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddLicitacaoForm = () => {
  const [licitacao, setLicitacao] = useState({
    numero: '',
    modalidade: '',
    titulo: '',
    objeto: '',
    dataCadastro: '',
    documentacao: []
  });

  const handleChange = (e) => {
    setLicitacao({
      ...licitacao,
      [e.target.name]: e.target.value
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLicitacao({
        ...licitacao,
        documentacao: [...licitacao.documentacao, { file, titulo: '' }]
      });
    }
  };

  const handleFileTitleChange = (index, e) => {
    const newDocumentacao = [...licitacao.documentacao];
    newDocumentacao[index].titulo = e.target.value;
    setLicitacao({
      ...licitacao,
      documentacao: newDocumentacao
    });
  };

  const handleRemoveFile = (index) => {
    const newDocumentacao = licitacao.documentacao.filter((_, i) => i !== index);
    setLicitacao({
      ...licitacao,
      documentacao: newDocumentacao
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('numero', licitacao.numero);
    formData.append('modalidade', licitacao.modalidade);
    formData.append('titulo', licitacao.titulo);
    formData.append('objeto', licitacao.objeto);

    // Ajustar a data de cadastro para 23:00
    const date = new Date(licitacao.dataCadastro);
    date.setUTCHours(23, 0, 0, 0);
    formData.append('dataCadastro', date.toISOString());

    licitacao.documentacao.forEach((doc, index) => {
      formData.append('documentacao', doc.file);
      formData.append(`titulo-${index}`, doc.titulo);
    });

    try {
      await addLicitacao(formData);
      toast.success('Licitação adicionada com sucesso');
      setLicitacao({
        numero: '',
        modalidade: '',
        titulo: '',
        objeto: '',
        dataCadastro: '',
        documentacao: []
      });
    } catch (error) {
      toast.error('Erro ao adicionar licitação: ' + (error.response ? error.response.data.error : 'Servidor indisponível'));

    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Paper elevation={3} style={{ padding: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          Adicionar Licitação
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Número"
            name="numero"
            value={licitacao.numero}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Modalidade"
            name="modalidade"
            value={licitacao.modalidade}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Título da Licitação"
            name="titulo"
            value={licitacao.titulo}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Objeto"
            name="objeto"
            value={licitacao.objeto}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
            required
          />
          <TextField
            label="Data de Cadastro"
            name="dataCadastro"
            type="date"
            value={licitacao.dataCadastro}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
          
            {licitacao.documentacao.map((doc, index) => (
              <Box key={index} mt={2} mb={2} display="flex" alignItems="center">
                <Typography variant="subtitle1">
                  Arquivo: {doc.file.name}
                </Typography>
                <TextField
                  label={`Título do Documento ${index + 1}`}
                  value={doc.titulo}
                  onChange={(e) => handleFileTitleChange(index, e)}
                  fullWidth
                  margin="normal"
                  required
                />
                <IconButton onClick={() => handleRemoveFile(index)} color="primary">
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          <Box mt={2} mb={2}>
            <Button
              variant="contained"
              component="label"
              startIcon={<AddCircleIcon />}
            >
              Adicionar Documento
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                accept="application/pdf,image/*"
              />
            </Button>
          </Box>
          <Box mt={2}>
            <Button type="submit" variant="contained" color="primary">
              Adicionar
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default AddLicitacaoForm;
