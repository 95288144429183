import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Paper, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginForm = () => {
  const [formData, setFormData] = useState({
    nomeUsuario: '',
    senha: ''
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://sistema.esct.net.br/back-end/api/usuarios/login', formData);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user)); // Armazena o usuário

      toast.success('Login bem-sucedido!');
      await delay(1000);

      // Redirecionar com base no tipo de usuário
      const userType = response.data.user.tipo;
      if (userType === 'coordenador') {
        window.location.href = '/admin/ciclos';
      } else {
        window.location.href = '/admin/justificativas';
      }
    } catch (error) {
      console.error('Erro ao fazer login', error);
      toast.error('Erro ao fazer login: ' + (error.response ? error.response.data.error : 'Servidor indisponível'));
    }
  };

  return (
    <Container component="main" maxWidth="xs" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Paper elevation={3} style={{ padding: 20, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src="/LOGO.png" alt="Logo" style={{ width: 250, marginBottom: 20 }} />
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Nome de Usuário"
            name="nomeUsuario"
            autoComplete="username"
            autoFocus
            value={formData.nomeUsuario}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="senha"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={formData.senha}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 8, marginBottom: 4 }}
          >
            Entrar
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default LoginForm;
