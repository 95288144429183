import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PrivateRoute = ({ allowedRoles }) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyUser = () => {
      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));

      if (token && user) {
        setIsAuthenticated(true);
        setUser(user);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    verifyUser();
  }, [location]);

  useEffect(() => {
    if (!isLoading && isAuthenticated && allowedRoles && !allowedRoles.includes(user.tipo)) {
      toast.error('Você não tem permissão para acessar esta página.');
    }
  }, [isLoading, isAuthenticated, allowedRoles, user]);

  if (isLoading) {
    return <div>Carregando...</div>; // Exibir um indicador de carregamento enquanto verifica o token
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (allowedRoles && !allowedRoles.includes(user.tipo)) {
    return <Navigate to="/admin/justificativas" replace />; // Redirecionar para uma página de "Não Autorizado"
  }

  return <Outlet />;
};

export default PrivateRoute;
