// src/pages/LicitacoesList.js
import React from 'react';
import LicitacoesTable from '../components/LicitacoesTable';
import { Helmet } from 'react-helmet';

const LicitacoesList = () => {
  return (
    <div>
      <Helmet>
        <title>Sistema ESCT - Licitações Cadastradas</title>
        <link rel="icon" type="image/png" href="https://www.esct.net.br/wp-content/uploads/2021/02/cropped-LOGO-ONG-32x32.png" sizes="16x16" />
      </Helmet>
      <LicitacoesTable />
    </div>
  );
};

export default LicitacoesList;
