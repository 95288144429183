import React, { useState, useEffect } from 'react';
import { Container, Paper, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { format, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

// Register the required components with Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const Dashboard = () => {
  const [justificativasPorTurma, setJustificativasPorTurma] = useState([]);
  const [startDate, setStartDate] = useState(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJustificativasPorTurma = async () => {
      try {
        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        const formattedEndDate = format(endDate, 'yyyy-MM-dd');
        console.log(`Enviando startDate: ${formattedStartDate}, endDate: ${formattedEndDate}`);

        const response = await axios.get(`https://sistema.esct.net.br/back-end/api/justificativas/turma?start=${formattedStartDate}&end=${formattedEndDate}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });

        console.log('Resposta recebida:', response.data);
        setJustificativasPorTurma(response.data);
      } catch (error) {
        console.error('Erro ao buscar justificativas por turma:', error);
        toast.error('Erro ao buscar justificativas por turma ' + (error.response ? error.response.data.error : 'Servidor indisponível'));
      }
    };
    fetchJustificativasPorTurma();
  }, [startDate, endDate]);

  const handleTurmaClick = (turma) => {
    navigate('/admin/justificativas', { state: { turma, startDate, endDate } });
  };

  const dataBarras = {
    labels: justificativasPorTurma.map(item => item.turma),
    datasets: [
      {
        label: 'Total de Justificativas',
        data: justificativasPorTurma.map(item => item.total),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const dataPizza = {
    labels: justificativasPorTurma.map(item => item.turma),
    datasets: [
      {
        data: justificativasPorTurma.map(item => item.total),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0'
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0'
        ],
      },
    ],
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
      <Paper elevation={3} style={{ padding: '2rem' }}>
        <Typography variant="h4" gutterBottom align="center">
          Dashboard de Justificativas
        </Typography>
        <Box display="flex" justifyContent="center" mb={2}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            locale={ptBR}
            className="form-control"
            placeholderText="Data Inicial"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            locale={ptBR}
            className="form-control"
            placeholderText="Data Final"
            style={{ marginLeft: '10px' }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Box width="48%">
            <Bar data={dataBarras} />
          </Box>
          <Box width="48%">
            <Pie data={dataPizza} />
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Turma</strong></TableCell>
                <TableCell><strong>Total de Justificativas</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {justificativasPorTurma.map((item) => (
                <TableRow key={item.turma} onClick={() => handleTurmaClick(item.turma)} style={{ cursor: 'pointer' }}>
                  <TableCell>{item.turma}</TableCell>
                  <TableCell>{item.total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default Dashboard;
