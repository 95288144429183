import React, { useState, useEffect } from 'react';
import { Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CicloList from '../components/CicloList';
import AddIcon from '@mui/icons-material/Add';
import { getCiclos } from '../api'; // Importar a função da API

const CiclosDashboard = () => {
  const [ciclos, setCiclos] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCiclos = async () => {
      try {
        const response = await getCiclos();
        setCiclos(response.data);
      } catch (error) {
        console.error('Erro ao buscar ciclos:', error);
      }
    };

    fetchCiclos();
  }, []);

  return (
    <Container>
      <Typography variant="h2">Escalas</Typography>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => navigate('/admin/ciclos/create')} style={{ marginBottom: '1rem' }}>
        Criar Nova Escala
      </Button>
      <CicloList ciclos={ciclos} setCiclos={setCiclos} />
    </Container>
  );
};

export default CiclosDashboard;
