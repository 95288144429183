import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box, Paper } from '@mui/material';
import { WhatsApp as WhatsAppIcon } from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const JustificativaConfirmada = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { formData, nomeArquivo } = location.state || {};

  const enviarWhatsApp = () => {
    const numeroTelefone = '5583996512207'; // Substitua pelo número de telefone da instituição
    const dataFormatada = format(parseISO(formData.dataAula + 'T00:00:00'), "EEEE, dd/MM/yyyy", { locale: ptBR });
    const documentoInfo = nomeArquivo ? `*Documento Anexado:* Sim` : '*Documento:* Não';
    const mensagem = `*Justificativa de Falta:*\n
*Nome:* ${formData.nomeCompleto}\n
*Turma:* ${formData.turma}\n
*Data da Aula:* ${dataFormatada}\n
*Justificativa:* ${formData.justificativa}\n
${documentoInfo}`;

    const url = `https://api.whatsapp.com/send?phone=${numeroTelefone}&text=${encodeURIComponent(mensagem)}`;
    window.open(url, '_blank');
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      <Box display="flex" justifyContent="center" mb={2}>
        <img src="/LOGO.png" alt="Logo" style={{ width: '200px', height: 'auto' }} />
      </Box>
      <Paper elevation={3} style={{ padding: '2rem' }}>
        <Typography gutterBottom align="center" fontSize={'1.4em'}>
          Justificativa Enviada com Sucesso
        </Typography>
        <Box mb={2}>
          <Typography variant="body2"><strong>Nome Completo:</strong> {formData.nomeCompleto}</Typography>
          <Typography variant="body2"><strong>Turma:</strong> {formData.turma}</Typography>
          <Typography variant="body2"><strong>Data da Aula:</strong> {format(parseISO(formData.dataAula + 'T00:00:00'), "EEEE, dd/MM/yyyy", { locale: ptBR })}</Typography>
          <Typography variant="body2"><strong>Justificativa:</strong> {formData.justificativa}</Typography>
          <Typography variant="body2"><strong>Documento Anexado:</strong> {nomeArquivo ? `Sim` : 'Não'}</Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<WhatsAppIcon />}
          onClick={enviarWhatsApp}
        >
          Enviar para WhatsApp
        </Button>
      </Paper>
    </Container>
  );
};

export default JustificativaConfirmada;
