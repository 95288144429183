import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Typography } from '@mui/material';
import { toast } from 'react-toastify';

const CicloForm = () => {
  const navigate = useNavigate();
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');

  useEffect(() => {
    if (dataInicio) {
      const date = new Date(dataInicio);
      date.setDate(date.getDate() + 27); // 4 semanas depois
      setDataFim(date.toISOString().split('T')[0]);
    }
  }, [dataInicio]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const cicloData = { data_inicio: dataInicio, data_fim: dataFim };
    axios.post('https://sistema.esct.net.br/back-end/api/ciclos', cicloData)
      .then(response => {
        toast.success('Ciclo criado com sucesso!');
        navigate(`/admin/escalas/create/${response.data.id}`);
      })
      .catch(error => {
        toast.error('Erro ao salvar o ciclo.');
        console.error(error);
      });
  };

  return (
    <Container>
      <Typography variant="h4">Criar Escala</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Data Início"
          type="date"
          value={dataInicio}
          onChange={(e) => setDataInicio(e.target.value)}
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="normal"
        />
        <TextField
          label="Data Fim"
          type="date"
          value={dataFim}
          readOnly
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">Criar Escala</Button>
      </form>
    </Container>
  );
};

export default CicloForm;
